<template>
  <div>
    <div class="mb-8">
      <div v-if="tabs.length > 1">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a
              v-for="tab in tabs"
              :key="tab.id"
              @click.prevent="selectTab(tab.id)"
              :class="[
                tab.active
                  ? 'border-primary text-primary-dark'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
              ]"
            >
              {{ tab.name }}
            </a>
          </nav>
        </div>
      </div>
    </div>

    <component :is="currentTab.component" />
  </div>
</template>

<script>
import RetailAnalytics from "@/views/admin/analytics/RetailAnalytics.vue";
import GeneralAnalytics from "@/views/admin/analytics/GeneralAnalytics.vue";
import { mapState } from "vuex";
import { setQueryParams, getQueryParams } from "@/utils/query_params";

export default {
  components: {
    RetailAnalytics,
    GeneralAnalytics,
  },

  data: () => {
    return {
      tabs: [],
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    retailAnalyticsAvailable() {
      return this.currentUser.retailAnalytics;
    },

    currentTab() {
      return this.tabs.find((tab) => tab.active);
    },
  },

  created() {
    this.initializeTabs();
    this.setTabFromQueryParams();
  },

  watch: {
    "$route.query"(oldQueryParams, newQueryParams) {
      if (oldQueryParams.tab !== newQueryParams.tab) {
        this.setTabFromQueryParams();
      }
    },
  },

  methods: {
    initializeTabs() {
      this.tabs = [
        {
          id: "general",
          name: "Vue d'ensemble",
          active: true,
          component: GeneralAnalytics,
        },
      ];

      if (this.retailAnalyticsAvailable) {
        this.tabs.push({
          id: "retail",
          name: "Suivi des ventes",
          active: false,
          component: RetailAnalytics,
        });
      }

      if (!getQueryParams().tab) {
        setQueryParams({ tab: this.currentTab.id });
      }
    },

    async selectTab(selectedTabId, { preserveQueryParams = false } = {}) {
      if (!preserveQueryParams) {
        await setQueryParams({ tab: selectedTabId });
      }

      this.tabs.forEach((tab) => {
        tab.active = tab.id === selectedTabId;
      });
    },

    setTabFromQueryParams() {
      const tab = getQueryParams().tab;
      if (tab) {
        this.selectTab(tab, { preserveQueryParams: true });
      }
    },
  },
};
</script>
