import { isPresent } from "@/utils/validation";
import ClaimService from "@/api/services/claims";

const initialState = {
  currentClaim: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getClaimFriendlyId: (state) => {
      return isPresent(state.currentClaim) ? state.currentClaim.friendlyId : null;
    },
    doesClaimExists: (state) => {
      return isPresent(state.currentClaim);
    },
    answers: (state) => {
      return isPresent(state.currentClaim) ? JSON.parse(JSON.stringify(state.currentClaim.answers)) : null;
    },
  },

  mutations: {
    setCurrentClaim(state, currentClaim) {
      state.currentClaim = currentClaim;
    },
  },

  actions: {
    async getClaim({ commit }, claimFriendlyId) {
      const response = await ClaimService.getClaimByFriendlyId(claimFriendlyId);
      if (response?.success) commit("setCurrentClaim", response.data);
      return response;
    },

    reset({ commit }) {
      commit("setCurrentClaim", null);
    },

    async impossibleClaimQuotationUpload({ state, dispatch }, { message, reason }) {
      const response = await ClaimService.impossibleQuotationUpload(state.currentClaim, message, reason);
      if (!response?.success) return response;
      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async uploadClaimQuotation({ state, dispatch }, { costItems, quotationFile, productState }) {
      const response = await ClaimService.addQuotationToClaim(
        state.currentClaim,
        costItems,
        quotationFile,
        productState,
      );
      if (!response?.success) return response;
      return dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async uploadClaimInvoice({ state, dispatch }, { costItems, invoiceFile }) {
      const response = await ClaimService.addInvoiceToClaim(state.currentClaim, costItems, invoiceFile);
      if (!response?.success) return response;
      return dispatch("getClaim", state.currentClaim.friendlyId);
    },
  },
};
