<template>
  <div>
    <Header />
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">{{ $t("My statements and bills") }}</h1>
    <InvoicesSummary :bill-amount="billAmount" :statement-amount="statementAmount" />
    <div class="my-7 flex w-full gap-10 items-center">
      <MonthlyDateFilterSelector
        class="w-1/4"
        placeholder="Sélectionner une période"
        @newDateSelected="updateDateFilters"
      />
      <SearchBarFilter
        v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
        :key="filterIndex"
        :filterName="filtersName"
        :filterIdx="filterIndex"
        :current-page="currentPage"
        :available-filters="filters"
        :selected-filters="currentFilteringData"
        @updateFilterSearch="updateCurrentFilteringData"
      />
    </div>
    <IbanMissing v-if="ibanMissing" />
    <div>
      <InvoicesContent :invoices="invoices" @redirectToInvoiceDetailsPage="redirectToInvoiceDetailsPage" />
      <Pagination
        :currentPage="currentPage"
        :pages="pages"
        :startIdx="startIdx"
        :endIdx="endIdx"
        :total="totalNumberOfInvoices"
        v-on:changePage="handleChangePage"
        v-if="pages.length > 1"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/remuneration/Header.vue";
import Pagination from "@/components/utils/Pagination.vue";
import AdminService from "../../../api/services/admin";
import InvoicesSummary from "@/components/invoice/InvoicesSummary.vue";
import InvoicesContent from "@/components/invoice/InvoicesContent.vue";
import SearchBarFilter from "@/components/menu/SearchBarFilter.vue";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";
import IbanMissing from "@/components/IbanMissing.vue";
import { setQueryParams, getQueryParams, arrayFilterIfPresent } from "@/utils/query_params";

const PAGE_SIZE = 25;

export default {
  components: {
    Header,
    InvoicesContent,
    InvoicesSummary,
    Pagination,
    SearchBarFilter,
    MonthlyDateFilterSelector,
    IbanMissing,
  },

  computed: {
    pages() {
      if (this.totalNumberOfInvoices < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfInvoices / PAGE_SIZE)).keys()].map((e) => e + 1);
    },

    startIdx() {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx() {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfInvoices);
    },

    availableFiltersWithOptions() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1),
      );
    },
  },

  data() {
    return {
      invoices: [],
      filters: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        transaction_types: [],
      },
      totalNumberOfInvoices: 0,
      currentPage: 1,
      currentFilteringData: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        transaction_types: [],
        start_date: null,
        end_date: null,
      },
      activeTab: 1,
      billAmount: null,
      statementAmount: null,
    };
  },

  mounted() {
    this.initQueryParams();
    this.retrieveInvoices();
  },

  methods: {
    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveInvoices();
    },

    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.handleChangePage(1);
    },

    updateDateFilters(startDate, endDate) {
      this.currentFilteringData["start_date"] = startDate;
      this.currentFilteringData["end_date"] = endDate;
      this.handleChangePage(1);
    },

    async retrieveInvoices() {
      setQueryParams({ page: this.currentPage, ...this.currentFilteringData });
      const response = await AdminService.getInvoices(this.currentPage, this.currentFilteringData);
      if (!response?.success) return;

      this.invoices = response.data.invoices;
      this.filters = response.data.filters;
      this.billAmount = response.data.billAmount;
      this.statementAmount = response.data.statementAmount;
      this.ibanMissing = response.data.ibanMissing;
      this.totalNumberOfInvoices = response.data.totalNumberOfInvoices;
    },

    redirectToInvoiceDetailsPage(invoiceId) {
      this.$router.push(`/admin/invoices/${invoiceId}`);
    },

    initQueryParams() {
      const queryParams = getQueryParams();
      this.currentFilteringData = {
        organizations: arrayFilterIfPresent(queryParams.organizations),
        stores: arrayFilterIfPresent(queryParams.stores),
        payment_statuses: arrayFilterIfPresent(queryParams.payment_statuses),
        transaction_types: arrayFilterIfPresent(queryParams.transaction_types),
        start_date: queryParams.start_date,
        end_date: queryParams.end_date,
      };
      this.currentPage = queryParams.page || 1;
    },
  },
};
</script>
