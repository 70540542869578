import StoreService from "@/api/services/store";
import AdminService from "@/api/services/admin";
import { setQueryParams, arrayFilterIfPresent } from "@/utils/query_params";

const initialState = {
  availableFilters: {
    status: [],
    stores: [],
  },
  selectedFilters: {
    status: [],
    stores: [],
  },
  claims: [],
  totalNumberOfClaims: null,
  keywords: "",
  selectedStoreId: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getClaims: (state) => {
      return state.claims;
    },
  },

  mutations: {
    setClaims(state, { claims, totalNumberOfClaims }) {
      state.claims = claims;
      state.totalNumberOfClaims = totalNumberOfClaims;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { status, stores }) {
      state.availableFilters.status = status;
      state.availableFilters.stores = stores;
    },

    setSelectedStoreId(state, selectedStoreId) {
      state.selectedStoreId = selectedStoreId;
    },

    setFilters(state, { filters }) {
      state.selectedFilters.stores = arrayFilterIfPresent(filters.stores);
      state.selectedFilters.status = arrayFilterIfPresent(filters.status);
      state.keywords = filters.keywords;
    },
  },

  actions: {
    async fetchClaims({ state, commit, rootGetters }, { page }) {
      const getClaimsFunction = rootGetters["navigation/isAdminPage"] ? AdminService.getClaims : StoreService.getClaims;
      let claimsParams = {
        page: page,
        stores: state.selectedFilters.stores,
        status: state.selectedFilters.status,
        keywords: state.keywords,
      };

      const response = await getClaimsFunction({
        storeId: state.selectedStoreId,
        data: claimsParams,
      });
      setQueryParams(claimsParams);

      if (!response?.success) return response;

      commit("setClaims", {
        claims: response.data.claims,
        totalNumberOfClaims: response.data.totalNumberOfClaims,
      });

      commit("setAvailableFilters", {
        status: response.data.filters.status,
        stores: response.data.filters.stores,
      });
      return response;
    },

    updateSearch({ commit, dispatch }, { searchString, page }) {
      commit("setKeywords", searchString);
      return dispatch("fetchClaims", {
        page: page,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page }) {
      commit("setFilter", { filterName, filterOptions });
      return dispatch("fetchClaims", {
        page: page,
      });
    },

    restrictStore({ commit }, storeId) {
      commit("setSelectedStoreId", storeId);
    },

    setFilters({ commit }, { filters }) {
      commit("setFilters", { filters: filters });
    },
  },
};
