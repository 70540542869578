<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <HeadlessUIDialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full lg:ml-20 max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
            >
              <div class="absolute top-4 right-4">
                <XIcon class="w-6 h-6 cursor-pointer" @click="closePaymentModal" />
              </div>

              <div>
                <InsuranceSaleTitle :product="product" />
                <SuccessModal v-if="paymentSuccess" :customer="customer" />
                <div v-else>
                  <div v-show="showConfirmationStep">
                    <ConsentCode
                      @verifyConsentCode="verifyConsentCode"
                      @sendConsentCode="sendConsentCode"
                      @reportMissingCode="reportMissingCode"
                    />
                  </div>
                  <div v-show="!showConfirmationStep">
                    <OfferSelector
                      v-if="isPresent(selectedOfferSku)"
                      :offers="lead.offers"
                      :selectedOfferSku="selectedOfferSku"
                      @update:selectedOfferSku="handleOfferChange"
                    />
                    <StripePayment
                      ref="stripePayment"
                      v-if="selectedOfferPrice?.stripe_client"
                      :offerPrice="selectedOfferPrice"
                      :discount="lead.discount"
                      @paymentSuccess="handlePaymentSuccess"
                      @showConfirmationStep="handleConfirmationStep"
                      @sendConsentCode="sendConsentCode"
                    />
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessUIDialog>
  </TransitionRoot>
</template>

<script>
import { Dialog as HeadlessUIDialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

import InsuranceSaleTitle from "@/components/payment/InsuranceSaleTitle.vue";
import OfferSelector from "@/views/pos/home/form/payment/OfferSelector.vue";
import StripePayment from "@/views/pos/home/form/payment/StripePayment";
import SuccessModal from "@/views/pos/home/form/SuccessModal.vue";
import ConsentCode from "@/views/pos/home/form/payment/ConsentCode.vue";

import LeadService from "@/api/services/lead";

import { isPresent } from "@/utils/validation";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeadlessUIDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XIcon,
    InsuranceSaleTitle,
    OfferSelector,
    StripePayment,
    SuccessModal,
    ConsentCode,
  },

  props: {
    isOpen: Boolean,
    lead: Object,
    product: Object,
    customer: Object,
  },

  data: () => {
    return {
      selectedOfferSku: "",
      isOffersInitialized: false,
      paymentSuccess: false,
      showConfirmationStep: false,
    };
  },

  watch: {
    "lead.offers": {
      immediate: true,
      deep: true,
      handler(newOffers) {
        if (isPresent(newOffers) && !this.isOffersInitialized) {
          this.$nextTick(() => {
            this.selectedOfferSku = newOffers[0].contract_sku;
            this.isOffersInitialized = true;
          });
        }
      },
    },
  },

  computed: {
    ...mapGetters("store", ["selectedStoreId"]),

    selectedOfferPrice() {
      if (this.selectedOfferSku) {
        return this.lead.offers.find((offer) => offer.contract_sku === this.selectedOfferSku);
      } else {
        return this.lead.offers[0];
      }
    },
  },

  methods: {
    isPresent,
    ...mapActions("notifications", ["notify"]),

    resetAttributes() {
      this.selectedOfferSku = "";
      this.isOffersInitialized = false;
      this.paymentSuccess = false;
      this.showConfirmationStep = false;
    },

    closePaymentModal() {
      this.$emit("closePaymentModal", this.paymentSuccess);
      this.resetAttributes();
    },

    handlePaymentSuccess(value) {
      this.paymentSuccess = value;
    },

    handleConfirmationStep(value) {
      this.showConfirmationStep = value;
    },

    async handleOfferChange(newOfferSku) {
      if (!isPresent(newOfferSku)) {
        console.error("Invalid SKU provided");
        return;
      }

      const selectedOfferIndex = this.lead.offers.findIndex((offer) => offer.contract_sku === newOfferSku);
      if (selectedOfferIndex === -1) {
        console.error("Selected offer not found");
        return;
      }

      const selectedOffer = this.lead.offers[selectedOfferIndex];

      const params = {
        uniqueToken: this.lead.uniqueToken,
        matchedContractId: selectedOffer.id,
      };

      const response = await LeadService.createPaymentIntent(this.selectedStoreId, params);
      if (!response?.success) return;

      const updatedOffer = {
        ...selectedOffer,
        stripe_client: response.data.stripe_client,
        payment_intent_id: response.data.payment_intent_id,
      };

      this.$emit("updateOffer", { index: selectedOfferIndex, offer: updatedOffer });
      this.selectedOfferSku = updatedOffer.contract_sku;
    },

    async sendConsentCode() {
      const { success } = await LeadService.sendConsentCode(this.selectedStoreId, this.lead.id);
      if (!success) return;

      this.showConfirmationStep = true;
      this.notify({
        category: "simple",
        type: "success",
        text: "The confirmation code has been sent.",
      });
    },

    async verifyConsentCode(consentCode) {
      const response = await LeadService.verifyConsentCode(this.selectedStoreId, this.lead.id, consentCode);
      if (response.status === 200) {
        this.$refs.stripePayment.handleSubmit();
      } else if (response.status === 422) {
        this.notify({
          category: "simple",
          type: "error",
          text: "Le code est invalide. Veuillez réessayer.",
        });
      }
    },

    async reportMissingCode() {
      const { success } = await LeadService.reportMissingCode(this.selectedStoreId, this.lead.id);
      if (!success) return;

      this.notify({
        category: "simple",
        type: "error",
        text: "We apologize for the inconvenience. We will contact the customer to finalize the sale.",
      });
      this.resetAttributes();
      this.$emit("closePaymentModal", true);
    },
  },
};
</script>
