<template>
  <div class="flex flex-col gap-10">
    <div class="bg-white shadow sm:rounded-t-md py-6 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-row justify-between items-center">
        <DateRangePicker
          class="text-sm w-full sm:w-80 rounded-md"
          :placeholder="$t('Select a time range')"
          v-model="dateRange"
          @update:modelValue="dateRangeUpdated"
        />

        <SearchBarFilter
          :enable-select-all="true"
          filterName="stores"
          :filterIdx="0"
          :available-filters="filters"
          :selected-filters="currentFilters"
          @updateFilterSearch="selectedStoresChanged"
        />
      </div>
    </div>

    <div v-if="isPresent(analytics)">
      <div class="mt-5">
        <dl class="grid grid-cols-1 gap-5 sm:grid-cols-4">
          <DataCard title="Contrats aujourd'hui" :value="analytics.general.today_contracts_count" />
          <DataCard title="Contrats hier" :value="analytics.general.yesterday_contracts_count" />
          <DataCard title="Contrats période" :value="analytics.general.period_contracts_count" />
          <DataCard title="Taux de résiliation" :value="formatPercentage(analytics.general.resiliation_rate)" />
        </dl>
      </div>
      <div class="mt-8">
        <p class="text-3xl font-bold ml-3">Ventes</p>
        <LineChart class="mt-8" title="Ventes de contrats" :data="contractsSoldData" :options="options" />
        <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <PieChart class="mt-5" title="Répartition par formule" :data="analytics.sales.vertical_repartition" />
          <PieChart
            class="mt-5"
            title="Répartition Mensuelle/Annuelle"
            :data="analytics.sales.monthly_annually_repartition"
          />
          <PieChart class="mt-5" title="Répartition par durée de contrat" :data="analytics.sales.period_repartition" />
          <div class="bg-white px-6 py-4 mt-4 rounded-lg shadow-lg" v-if="isPresent(analytics.sales.sellers_ranking)">
            <h1 class="text-xl pb-4 pl-2 font-bold tracking-tight text-gray-900 text-left">Classement des vendeurs</h1>
            <div class="px-4 sm:px-6 lg:px-8">
              <div class="mt-3 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle">
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                          ></th>
                          <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Vendeur</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Ventes</th>
                          <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
                            Résiliation
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="(seller, index) in analytics.sales.sellers_ranking" :key="seller.id">
                          <td
                            class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          >
                            {{ index + 1 }}
                          </td>
                          <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{ seller.name }}</td>
                          <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{ seller.sales_count }}</td>
                          <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                            {{ seller.cancellation_rate }} %
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <p class="text-3xl font-bold ml-3">Sinistres</p>
        <dl class="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard title="Sinistres déclarés" :value="analytics.claims.claims_filed_count" />
          <DataCard title="Taux d'acceptation" :value="formatPercentage(analytics.claims.accepted_claims_rate)" />
          <DataCard title="Montant remboursé" :value="formatPrice(analytics.claims.reimbursed_claims_amount)" />
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { DateRangePicker } from "@estaly/ui";
import { currentMonthRange } from "@/utils/date";
import { mapActions } from "vuex";
import AnalyticsService from "@/api/services/analytics";
import SearchBarFilter from "@/components/menu/SearchBarFilter.vue";
import LineChart from "@estaly/ui/src/LineChart.vue";
import PieChart from "@estaly/ui/src/PieChart.vue";
import DataCard from "@estaly/ui/src/DataCard.vue";

import { isPresent } from "@/utils/validation";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: {
    PieChart,
    DateRangePicker,
    SearchBarFilter,
    LineChart,
    DataCard,
  },

  data() {
    return {
      dateRange: currentMonthRange(),
      analytics: {},
      currentFilters: {
        stores: [],
      },
      filters: {
        stores: [],
      },
      requestTimeout: null,
    };
  },

  mounted() {
    this.withLoader(this.fetchAnalytics);
  },

  computed: {
    apiParams() {
      return {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        stores: this.currentFilters.stores,
      };
    },

    contractsSoldData() {
      return {
        "active contracts": {
          color: "rgba(150,245,103,0.6)",
          value: Object.entries(this.analytics.sales.active_plan_sales_count).map(([key, value]) => {
            return { date: key, value: value };
          }),
        },
        "sold contracts": {
          color: "#1629cd",
          value: Object.entries(this.analytics.sales.plan_sales_count).map(([key, value]) => {
            return { date: key, value: value };
          }),
        },
        "sales target": {
          color: "purple",
          value: [],
        },
      };
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "Date", // Optional title for the x-axis
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Ventes", // Optional title for the y-axis
            },
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: this.plugins,
      };
    },

    plugins() {
      return {
        annotation:
          this.analytics.sales.target_period > 0
            ? {
                annotations: {
                  targetLine: {
                    type: "line",
                    yMin: this.analytics.sales.target_period, // Target value on Y-axis
                    yMax: this.analytics.sales.target_period, // Same value to create a horizontal line
                    borderColor: "purple",
                    borderWidth: 2,
                    borderDash: [5, 5],
                    display: (config) => {
                      return config.chart.isDatasetVisible(2);
                    },
                    label: {
                      content: "Objectif",
                      enabled: true,
                      position: "center",
                      backgroundColor: "white",
                      font: {
                        weight: "bold",
                      },
                    },
                  },
                },
              }
            : false,
        datalabels: false,
        legend: {
          onClick: (click, legendItem, legend) => {
            const datasets = legend.legendItems.map((dataset) => {
              return dataset.text;
            });

            const index = datasets.indexOf(legendItem.text);

            if (legend.chart.isDatasetVisible(index) === true) {
              legend.chart.hide(index);
            } else {
              legend.chart.show(index);
            }
          },
          labels: {
            usePointStyle: true,
            generateLabels: (chart) => {
              // line strike-through for hidden datasets
              let visibility = [];
              for (let i = 0; i < chart.data.datasets.length; i++) {
                if (chart.isDatasetVisible(i) === true) {
                  visibility.push(false);
                } else {
                  visibility.push(true);
                }
              }
              // point style
              return chart.data.datasets.map((dataset, index) => ({
                text: dataset.label,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.borderColor,
                pointStyle: "line",
                hidden: visibility[index],
                lineDash: index === 2 ? [4, 2] : [], // dashed line for target line
                lineWidth: 2,
              }));
            },
          },
          position: "top",
        },
      };
    },
  },

  methods: {
    formatPrice,
    isPresent,
    ...mapActions("store", ["withLoader"]),

    dateRangeUpdated(dateRange) {
      this.dateRange = dateRange;
      this.withLoader(this.fetchAnalytics);
    },

    async fetchAnalytics() {
      const response = await AnalyticsService.getGeneralAnalytics(this.apiParams);
      if (response?.success) {
        this.analytics = response.data.analytics;
        this.currentFilters.stores = this.analytics.stores;
        this.filters.stores = response.data.available_stores;
      }
    },

    selectedStoresChanged(filterName, filterOptions) {
      this.currentFilters[filterName] = filterOptions;

      // Not fetching the analytics when no store is selected to let the user choose one
      if (filterName === "stores" && filterOptions.length === 0) return;

      this.withLoader(this.fetchAnalytics);
    },

    formatPercentage(value) {
      return `${Math.round(value * 100)} %`;
    },
  },
};
</script>
