<template>
  <div class="bg-white px-6 py-4 mt-4 rounded-lg shadow-lg">
    <h1 class="text-xl pb-4 pl-2 font-bold tracking-tight text-gray-900 text-left">
      {{ title }}
    </h1>
    <PieChart :chart-data="chartData" :options="chartOptions" height="300" width="300"/>
  </div>
</template>

<script>
import { PieChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables, ChartDataLabels);

// Define an array of 10 colors
const COLORS = [
  "#FF5733", // Red
  "#33FF57", // Green
  "#3357FF", // Blue
  "#F1C40F", // Yellow
  "#8E44AD", // Purple
  "#E74C3C", // Light Red
  "#2ECC71", // Light Green
  "#3498DB", // Light Blue
  "#F39C12", // Orange
  "#1ABC9C", // Turquoise
];

export default {
  components: { PieChart },
  props: ["title", "data", "options"],

  computed: {
    chartData() {
      return {
        labels: Object.keys(this.data),
        datasets: [
          {
            data: Object.values(this.data),
            backgroundColor: this.generateRandomColors(Object.keys(this.data).length),
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',  // Percentage of the chart to be left empty in the center (adjust this value)
        plugins: {
          legend: {
            display: true,
            position: "left",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
            },
          },
          datalabels: {
            color: "#333232",
            font: {
              weight: "bold",
              size: 12,
            },
            formatter: (value, context) => {
              let total = context.dataset.data.reduce((acc, val) => acc + val, 0);
              let percentage = ((value / total) * 100).toFixed(2) + "%"; // Format percentage
              return percentage; // Return the percentage
            },
            anchor: "center",
          },
        },
      }
    },
  },

  methods: {
    generateRandomColors(count) {
      const colors = [];
      for (let i = 0; i < count; i++) {
        let color;
        do {
          // Generate a random muted color
          color = `rgba(${this.randomMuted255()}, ${this.randomMuted255()}, ${this.randomMuted255()}, 0.6)`;
        } while (this.isGray(color)); // Check if the color is gray and regenerate if it is
        colors.push(color);
      }
      return colors;
    },

// Helper function to generate a random muted value (between 100 and 200)
    randomMuted255() {
      return Math.floor(Math.random() * 101) + 100; // This generates a value between 100 and 200
    },

// Helper function to check if the color is gray
    isGray(color) {
      const rgba = color.match(/\d+/g); // Extracts the RGB values from the rgba string
      const r = parseInt(rgba[0]);
      const g = parseInt(rgba[1]);
      const b = parseInt(rgba[2]);

      // Check if the color is gray: the red, green, and blue components are too similar
      return Math.abs(r - g) < 20 && Math.abs(g - b) < 20 && Math.abs(r - b) < 20;
    }
  },
};
</script>