<template>
  <div>
    <loading
      v-model:active="this.isLoading"
      v-bind:is-full-page="false"
      v-bind:lock-scroll="true"
      v-bind:opacity="0.7"
      v-bind:color="'#4f46e5'"
      class="absolute top-0 left-0 w-full h-full"
    />
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
export default {
  components: {
    Loading,
  },
  computed: {
    ...mapState("store", ["isLoading"]),
  },
};
</script>
