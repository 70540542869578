import { isPresent } from "@/utils/validation";
import router from "@/router";

export function getQueryParams() {
  return router.currentRoute.value.query;
}

export async function setQueryParams(params) {
  let cleanParams = {};

  for (const key in params) {
    if (isPresent(params[key])) {
      cleanParams[key] = params[key];
    }
  }

  // Write the new query params to the URL, and save them in the browser history
  // Will not trigger a page reload or navigation
  await router.push({ query: cleanParams });
}

// Always return an array, even if the filter is not present or is a single value
export function arrayFilterIfPresent(filter) {
  return filter ? [filter].flat() : [];
}
