<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Claim coverage information") }}
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6">
        <div>
          <dt class="text-sm font-bold text-gray-500">Statut de traitement</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FieldSpan
              v-bind:style-to-add="`${this.getClaimStatusBannerStyle(claim.status.color)} font-bold`"
              v-bind:value="claim.status.text"
            />
          </dd>
        </div>
        <div v-if="isPresent(claim?.handlingType)">
          <dt class="text-sm font-bold text-gray-500">Type de prise en charge</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <FieldSpan
              v-bind:style-to-add="`bg-${claim.handlingType.color}-100 font-bold text-${claim.handlingType.color}-800`"
              v-bind:value="claim.handlingType.description"
            />
          </dd>
        </div>
        <div v-if="isPresent(claim?.coverages?.compensationValue)">
          <dt class="text-sm font-bold text-gray-500">Valeur maximale de prise en charge</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ formatPrice(claim.coverages.compensationValue) }}
          </dd>
        </div>
        <div v-if="isPresent(claim?.coverages?.claimLimit?.description)">
          <dt class="text-sm font-bold text-gray-500">Sinistres autorisés par période</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.coverages.claimLimit.description }}
          </dd>
        </div>
        <div v-if="isPresent(claim?.coverages?.claimLimit?.claimsTreatedThisPeriod)">
          <dt class="text-sm font-bold text-gray-500">Sinistres traîtés sur cette période</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.coverages.claimLimit.claimsTreatedThisPeriod }}
          </dd>
        </div>
        <div v-if="isPresent(claim?.coverages?.claimLimit?.errorMessage)">
          <dt class="text-sm font-bold text-gray-500">Commentaires</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ claim.coverages.claimLimit.errorMessage }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { isPresent } from "@/utils/validation";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: { FieldSpan },
  props: ["claim"],

  methods: {
    isPresent,
    formatPrice,
    getClaimStatusBannerStyle: function (color) {
      return `bg-${color}-100 text-${color}-800`;
    },
  },
};
</script>
