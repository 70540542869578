<template>
  <div>
    <div aria-live="assertive" class="fixed inset-0 px-4 pointer-events-none sm:px-6 sm:items-start z-50">
      <SimpleNotification v-for="message in simpleMessages" :key="message.id" :message="message" @close="close" />
    </div>
    <ModalNotification v-if="lastModalMessage" :message="lastModalMessage" @close="close" />
  </div>
</template>

<script>
import ModalNotification from "./ModalNotification.vue";
import SimpleNotification from "./SimpleNotification.vue";

export default {
  components: {
    ModalNotification,
    SimpleNotification,
  },
  computed: {
    lastModalMessage() {
      return this.$store.getters["notifications/lastModalMessage"];
    },
    simpleMessages() {
      return this.$store.getters["notifications/simpleMessages"];
    },
  },
  methods: {
    close(id) {
      this.$store.dispatch("notifications/REMOVE_MESSAGE", id);
    },
  },
  watch: {
    simpleMessages: {
      handler(messages) {
        // headlessui (for Modals) set inert, this breaks the ability to close notifications
        if (messages.length > 0) document.getElementById("app").inert = false;
      },
    },
  },
};
</script>
