<template>
  <TabsNavigation :tabs="tabs" />
</template>

<script>
import { TabsNavigation } from "@estaly/ui";

export default {
  components: { TabsNavigation },

  data: () => ({
    tabs: [
      { name: "remuneration_invoices", path: "/admin/invoices", displayName: "Mes relevés" },
      { name: "remuneration_retributions", path: "/admin/retributions", displayName: "Primes vendeurs" },
    ],
  }),
};
</script>
