<template>
  <CollapsableContent :title="$t('Proposed offers')" :open="false">
    <div>
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr class="hidden md:table-row">
              <th
                scope="col"
                class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Title") }}
              </th>
              <th
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Price range") }}
              </th>
              <th
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Price incl. VAT") }}
              </th>
              <th
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Merchant's commission") }}
              </th>
              <th
                v-if="isOfflineStore"
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Annualized merchant's commission") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white text-gray-900 text-xs font-medium">
            <tr v-for="matchedContract in this.matchedContracts" v-bind:key="matchedContract.id">
              <td class="whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4">
                {{ matchedContract.title }}
              </td>
              <td class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4">
                {{ matchedContract.priceBandMin }} à {{ matchedContract.priceBandMax }}
              </td>
              <td class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4">
                {{ matchedContract.price }}
              </td>
              <td class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4">
                {{ matchedContract.theoricalStoreCommissionAmount }}
              </td>
              <td v-if="isOfflineStore" class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4">
                {{ matchedContract.annualizedStoreCommissionAmount }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";

export default {
  components: {
    CollapsableContent,
  },
  props: ["matchedContracts", "isOfflineStore"],
};
</script>
