<template>
  <div class="bg-white px-6 py-4 mt-4 rounded-lg shadow-lg">
    <h1 class="text-xl pb-4 pl-2 font-bold tracking-tight text-gray-900 text-left">
      {{ title }}
    </h1>
    <LineChart :chartData="chartData" :options="options" :width="100" :height="300" :key="chartKey"/>
  </div>
</template>
<script>
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import ChartAnnotation from "chartjs-plugin-annotation";

Chart.register(...registerables, ChartAnnotation);

export default {
  props: ["title", "data", "options", "plugins"],

  components: {
    LineChart,
  },

  watch: {
    chartData: {
      handler() {
       this.chartKey = this.chartKey + 1
      },
      deep: true, // Ensures nested changes are detected
    },
  },

  data: () => {
    return {
      chartKey: 0, // Used to force re-rendering
    };
  },
  computed: {
    chartData: function () {
      return {
        datasets: Object.entries(this.data).map(([label, entryData]) => {
          return {
            borderColor: entryData.color,
            fill: false,
            borderWidth: 2,
            pointRadius: 0,
            parsing: true,
            label: this.$t(label),
            data: entryData.value.map((dataPoint) => {
              return { x: dataPoint.date, y: dataPoint.value };
            }),
          };
        }),
      };
    },
  },
};
</script>
