<template>
  <div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 pb-12 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Période</th>
                  <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Entité</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                  <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Libellé</th>
                  <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">Montant</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statut paiement</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Télécharger</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="invoice in this.invoices"
                  :key="invoice.month"
                  @click="redirectToInvoiceDetailsPage(invoice.customId)"
                  class="cursor-pointer hover:bg-gray-100"
                >
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    {{ invoice.monthYear }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    {{ invoice.salesChannel }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    {{ $t(invoice.transactionType) }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    {{ invoice.customId }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                    {{ formatPrice(invoice.amount) }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    <FieldSpan
                      v-bind:style-to-add="getPaymentStatusFieldDisplayStyle(invoice.paymentStatus)"
                      v-bind:value="invoice.paymentStatus"
                    />
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    <div class="flex">
                      <CloudDownloadIcon class="h-6 w-6 text-primary-dark cursor-pointer" aria-hidden="true" />
                      <a :href="invoice.fileUrl" class="pl-2 text-base font-semibold text-primary-dark" target="_blank">
                        {{ $t("Download") }}
                      </a>
                    </div>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 text-center">
                    <InformationCircleIcon class="h-6 w-6 text-gray-300 cursor-pointer" aria-hidden="true" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { CloudDownloadIcon, InformationCircleIcon } from "@heroicons/vue/outline";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: {
    FieldSpan,
    CloudDownloadIcon,
    InformationCircleIcon,
  },

  props: ["invoices"],

  methods: {
    formatPrice,
    getPaymentStatusFieldDisplayStyle(paymentStatus) {
      switch (paymentStatus) {
        case "pending_payment":
          return "bg-yellow-100 text-yellow-800";
        case "paid":
          return "bg-green-100 text-green-800";
        default:
          return "bg-blue-100 text-blue-800";
      }
    },
    redirectToInvoiceDetailsPage: function (invoiceId) {
      this.$emit("redirectToInvoiceDetailsPage", invoiceId);
    },
  },
};
</script>
