<template>
  <CollapsableContent :title="computedTitle" :open="false" @openCollapse="fetchData">
    <div class="bg-white rounded-xl py-6 px-4">
      <div class="flex justify-end mb-4">
        <div class="flex gap-8">
          <SearchBarFilterWithSingleOption
            v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
            :key="filterIndex"
            :filterName="filtersName"
            :filterIdx="filterIndex"
            :available-filters="filters"
            :selected-filters="currentFilteringData"
            @updateFilterSearch="updateCurrentFilteringData"
          />
        </div>
      </div>
      <div v-if="categories && categories.length == 0" class="bg-white rounded-xl py-6 px-4">Pas de rémunération</div>
      <div v-else>
        <div v-if="split" class="overflow-x-auto">
          <table class="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th class="border border-gray-300 px-4 py-2 bg-gray-400 text-white" rowspan="2">{{ $t("Formula") }}</th>
                <th class="border border-gray-300 px-4 py-2 bg-gray-400 text-white" rowspan="2">{{ $t("Bands") }}</th>
                <th class="border border-gray-300 px-4 py-2 bg-gray-200" :colspan="dynamicColumns.length">
                  {{ $t("Remuneration grid") }}
                </th>
                <th class="border border-gray-300 px-4 py-2 bg-gray-200" :colspan="dynamicColumns.length">
                  {{ $t("Sales count") }}
                </th>
                <th class="border border-gray-300 px-4 py-2 bg-gray-200" :colspan="dynamicColumns.length">
                  {{ $t("Remuneration") }}
                </th>
              </tr>
              <tr>
                <template v-for="key in dynamicColumns" :key="key">
                  <th class="text-xs bg-gray-50 font-bold border border-gray-300 px-4 py-2">
                    {{ $t(`termLength.${key}`) }}
                  </th>
                </template>
                <template v-for="key in dynamicColumns" :key="key">
                  <th class="text-xs bg-gray-50 font-bold border border-gray-300 px-4 py-2">
                    {{ $t(`termLength.${key}`) }}
                  </th>
                </template>
                <template v-for="key in dynamicColumns" :key="key">
                  <th class="text-xs bg-gray-50 font-bold border border-gray-300 px-4 py-2">
                    {{ $t(`termLength.${key}`) }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="category in categories" :key="category.name">
                <tr v-for="(data, band, index) in getPriceBands(category)" :key="band">
                  <td
                    v-if="index === 0"
                    :rowspan="Object.keys(getPriceBands(category)).length"
                    class="text-xs border border-gray-300 border-y-black px-4 py-2 font-bold"
                  >
                    {{ $t(`formula.${category.name}`) }}
                  </td>
                  <td class="text-xs border border-gray-300 px-4 py-2">
                    {{ formatBand(band) }}
                  </td>
                  <td
                    v-for="key in dynamicColumns"
                    :key="'grille-' + key"
                    class="text-xs border border-gray-300 px-4 py-2 text-right"
                  >
                    {{ formatPrice(data["remuneration_grid"][key]) || "＿" }}
                  </td>
                  <td
                    v-for="key in dynamicColumns"
                    :key="'ventes-' + key"
                    class="text-xs border border-gray-300 px-4 py-2 text-right"
                  >
                    {{ data["sales_count"][key] || "＿" }}
                  </td>
                  <td
                    v-for="key in dynamicColumns"
                    :key="'remuneration-' + key"
                    class="text-xs border border-gray-300 px-4 py-2 text-right"
                  >
                    {{ formatPrice(data["remuneration"][key]) || "＿" }}
                  </td>
                </tr>
              </template>
              <tr class="font-bold bg-gray-100">
                <td colspan="2" class="text-xs border border-gray-300 px-4 py-2 text-center">{{ splitTitle }}</td>
                <td v-for="key in dynamicColumns" :key="'repartition-grille-' + key" class="px-4 py-2"></td>
                <td
                  v-for="key in dynamicColumns"
                  :key="'repartition-ventes-' + key"
                  class="text-xs border border-gray-300 px-4 py-2 text-right"
                >
                  {{ split["sales_count"][key] }}
                </td>
                <td
                  v-for="key in dynamicColumns"
                  :key="'repartition-remuneration-' + key"
                  class="text-xs border border-gray-300 px-4 py-2 text-right"
                >
                  {{ formatPrice(split["remuneration"][key]) }}
                </td>
              </tr>
              <tr class="font-bold bg-gray-100 border border-gray-300">
                <td colspan="2" class="text-xs border border-gray-300 px-4 py-2 text-center">{{ totalTitle }}</td>
                <td :colspan="dynamicColumns.length" class="text-xs px-4 py-2"></td>
                <td :colspan="dynamicColumns.length" class="text-xs border border-gray-300 px-4 py-2 text-right">
                  {{ total["sales_count"] }}
                </td>
                <td :colspan="dynamicColumns.length" class="text-xs border border-gray-300 px-4 py-2 text-right">
                  {{ formatPrice(total["remuneration"]) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import SearchBarFilterWithSingleOption from "@/components/menu/SearchBarFilterWithSingleOption.vue";
import AdminService from "@/api/services/admin";

import { mapMutations } from "vuex";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: { SearchBarFilterWithSingleOption, CollapsableContent },

  props: {
    invoice: {
      type: Object,
      required: true,
    },
    recurringBreakdown: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dynamicColumns: null,
      categories: null,
      split: null,
      total: null,
      filters: {},
      currentFilteringData: {},
    };
  },

  computed: {
    availableFiltersWithOptions() {
      return Object.fromEntries(Object.entries(this.filters).filter(([key, options]) => key && options?.length > 1));
    },

    params() {
      return {
        ...this.currentFilteringData,
        recurring_breakdown: this.recurringBreakdown,
      };
    },

    computedTitle() {
      let title;

      if (this.recurringBreakdown === true) {
        if (this.currentFilteringData.sales_channel) {
          title = `Rémunération récurrente - ${this.currentFilteringData.sales_channel}`;
        } else {
          title = "Rémunération récurrente";
        }
      } else {
        if (this.currentFilteringData.seller_name) {
          title = `Rémunération générée par les nouvelles ventes - ${this.currentFilteringData.seller_name}`;
        } else if (this.currentFilteringData.sales_channel) {
          title = `Rémunération générée par les nouvelles ventes - ${this.currentFilteringData.sales_channel}`;
        } else {
          title = "Rémunération générée par les nouvelles ventes";
        }
      }

      return title;
    },

    splitTitle() {
      if (!this.invoice || !this.currentFilteringData) return "";

      let title;

      if (this.currentFilteringData.seller_name) {
        title = "Répartition vendeur - " + this.currentFilteringData.seller_name;
      } else if (this.currentFilteringData.sales_channel) {
        title = "Répartition magasin - " + this.currentFilteringData.sales_channel;
      } else if (this.invoice.subjectType === "Store") {
        title = "Répartition magasin";
      } else {
        title = "Répartition réseau";
      }

      return title;
    },

    totalTitle() {
      if (!this.invoice || !this.currentFilteringData) return "";

      let title;

      if (this.currentFilteringData.seller_name) {
        title = "Total vendeur - " + this.currentFilteringData.seller_name;
      } else if (this.currentFilteringData.sales_channel) {
        title = "Total magasin - " + this.currentFilteringData.sales_channel;
      } else if (this.invoice.subjectType === "Store") {
        title = "Total magasin";
      } else {
        title = "Total réseau";
      }

      return title;
    },
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),
    formatPrice,

    updateCurrentFilteringData(filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.fetchData();
    },

    async fetchData() {
      this.setIsLoading(true);
      const response = await AdminService.getInvoiceBreakdown(this.invoice.id, this.params);
      if (response?.success) {
        this.categories = response.data.breakdown.categories;
        this.split = response.data.breakdown.split;
        this.total = response.data.breakdown.total;
        this.filters = response.data.filters;
        this.extractDynamicColumns();
      }
      this.setIsLoading(false);
    },

    extractDynamicColumns() {
      if (this.categories.length == 0) return [];

      const firstCategory = this.categories?.[0];
      if (!firstCategory) return [];
      const priceBandKeys = Object.keys(firstCategory).filter((key) => key !== "name");
      const firstPriceBand = firstCategory[priceBandKeys[0]];
      if (!firstPriceBand) return [];
      this.dynamicColumns = Object.keys(firstPriceBand["remuneration_grid"] || {});
    },

    getPriceBands(category) {
      const priceBandKeys = Object.keys(category).filter((key) => key !== "name");
      return priceBandKeys.reduce((bands, key) => {
        bands[key] = category[key];
        return bands;
      }, {});
    },

    formatBand(band) {
      if (!band) return "";
      const [min, max] = band.split("_").map(Number);
      return `${this.formatPrice(min)} à ${this.formatPrice(max)}`;
    },
  },
};
</script>
