<template>
  <div>
    <Header />
    <Alert class="mb-5">
      <template #title>Configurer le système de primes venders</template>
      <p class="text-sm">
        Afin de motiver vos vendeurs à proposer les services d'Estaly, vous avez la possibilité de leur rétribuer une
        partie de votre rémunération. Une fois les primes configurées, vous pouvez suivre le montant à reverser mois par
        mois à chacun de vos vendeurs depuis l'onglet "Mes relevés" en cliquant sur le détail de relevé
      </p>
    </Alert>

    <Selector
      id="store"
      label="Magasin"
      name="store"
      required
      v-model="selectedStoreId"
      :options="storeOptions"
      class="mb-5"
    />

    <template v-if="store">
      <div class="mb-5">
        <h3 class="text-lg font-bold">Configurer le système de primes vendeurs : {{ store.salesChannel }}</h3>
        <p class="my-3">
          Configurez le système de primes en montant fixe par offre ou pourcentage de votre rémunération
        </p>

        <div class="grid grid-cols-2 gap-5">
          <div class="shadow-lg py-5 px-7 flex flex-col justify-between">
            <h4 class="text-lg font-bold">Montant fixe par offre</h4>
            <p>Pour chaque offre, indiquez le montant que vous souhaitez rétribuer à vos vendeurs</p>
            <div class="text-center m-2">
              <GenericButton @click="configFixedPriceModalOpen = true">Configurer</GenericButton>
            </div>
            <Modal size="big" :open="configFixedPriceModalOpen" @close="configFixedPriceModalOpen = false">
              <h4 class="text-lg font-bold">Rétribution en montant fixe</h4>
              <p>Indiquer le montant de la rétribution par offre et par durée.</p>

              <RetributionTable
                :store="store"
                :retributions="retributions"
                editable
                @editRetribution="editRetribution"
              />
              <div class="mt-4 flex justify-center">
                <GenericButton @onClick="submitEditFixedPrice">Valider la rétribution</GenericButton>
              </div>
            </Modal>
          </div>

          <div class="shadow-lg py-5 px-7 flex flex-col justify-between">
            <h4 class="text-lg font-bold">Primes en pourcentage</h4>
            <p>
              Indiquez le montant en pourcentage de votre rémunération que vous souhaitez rétribuer. Nous appliquerons
              un arroundi à l'entier supérieur.
            </p>
            <div class="text-center m-2">
              <GenericButton @click="configPercentageModalOpen = true">Configurer</GenericButton>
            </div>
            <Modal :open="configPercentageModalOpen" @close="configPercentageModalOpen = false">
              <h4 class="text-lg font-bold">Rétribution en pourcentage</h4>
              <p class="mb-4">Indiquez le pourcentage de la rémunération que vous souhaitez rétribuer aux vendeurs.</p>
              <FormField
                id="percentage_value"
                label="Pourcentage de la rémunération à rétribuer"
                name="percentage_value"
                trailing-add-ons="%"
                required
                v-model="percentageValue"
              />
              <div class="mt-4 flex justify-center">
                <GenericButton @onClick="submitEditPercentage">Valider la rétribution</GenericButton>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <h3 class="text-lg font-bold">Grille de rétribution</h3>
        <RetributionTable :store="store" :retributions="retributions" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Alert } from "@estaly/ui";
import Modal from "@/components/modal/Modal.vue";
import FormField from "@/components/utils/FormField.vue";
import Selector from "@/components/utils/Selector.vue";
import Header from "@/components/remuneration/Header.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import RetributionTable from "@/components/remuneration/RetributionTable.vue";
import { mapActions } from "vuex";
import StoreService from "@/api/services/store.js";

export default {
  components: {
    Header,
    Selector,
    FormField,
    Alert,
    Modal,
    GenericButton,
    RetributionTable,
  },

  data() {
    return {
      selectedStoreId: null,
      configPercentageModalOpen: false,
      configFixedPriceModalOpen: false,
      percentageValue: null,
      retributions: [],
      retributionsFixedPriceChanges: {},
    };
  },

  created() {
    this.selectedStoreId = this.$route.query.storeId || this.filteredStores[0]?.id;
    this.fetchRetributions();
  },

  computed: {
    ...mapState("store", ["stores"]),

    filteredStores() {
      return this.stores.filter((store) => store.source === "offline" && store.status === "active");
    },

    storeOptions() {
      return this.filteredStores.map((store) => ({
        label: store.salesChannel,
        value: store.id,
      }));
    },

    store() {
      return this.stores.find((store) => store.id == this.selectedStoreId);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify", "clearAll"]),

    async fetchRetributions() {
      const response = await StoreService.getRetributions(this.store.id);
      if (!response.success) return;

      this.retributions = response.data;
    },

    async submitEditPercentage() {
      if (!this.percentageValue) return document.getElementById("percentage_value").focus();

      const response = await StoreService.updateRetributionsPercentage(this.store.id, {
        percentage_value: this.percentageValue / 100,
      });
      if (!response.success) return;

      await this.fetchRetributions();
      await this.notify({
        category: "simple",
        type: "success",
        title: "Mise à jour effectuée",
      });
      this.configPercentageModalOpen = false;
    },

    editRetribution(retributionId, value) {
      this.retributionsFixedPriceChanges[retributionId] = value;
    },

    async submitEditFixedPrice() {
      this.clearAll();
      const errors = [];

      await Promise.all(
        Object.entries(this.retributionsFixedPriceChanges).map(async ([retributionId, value]) => {
          const response = await StoreService.updateRetributionsFixedPrice(this.store.id, retributionId, {
            fixed_value: value,
          });
          if (!response.success) errors.push(retributionId);
          else {
            this.lowlightError(retributionId);
            delete this.retributionsFixedPriceChanges[retributionId];
          }
        }),
      );

      await this.fetchRetributions();

      if (errors.length === 0) {
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
        this.configFixedPriceModalOpen = false;
      } else {
        errors.forEach((retributionId) => {
          this.highlightError(retributionId);
        });
      }
    },

    highlightError(retributionId) {
      document.getElementById(`retribution-${retributionId}`).classList.add("border-2", "border-red-500");
    },

    lowlightError(retributionId) {
      document.getElementById(`retribution-${retributionId}`).classList.remove("border-2", "border-red-500");
    },
  },

  watch: {
    selectedStoreId(newStoreId, oldStoreId) {
      if (oldStoreId) {
        const url = new URL(location);
        url.searchParams.set("storeId", newStoreId);
        history.pushState({}, "", url);
        if (this.store.sellerIncentivePercentage) this.percentageValue = this.store.sellerIncentivePercentage * 100;
        this.fetchRetributions();
      }
    },
  },
};
</script>
