<template>
  <div>
    <Table :columns="columns" :data="rows" v-if="rows.length > 0" class="mt-2">
      <template #editable="{ row, column }">
        <FormField
          v-if="retributionId(row, column)"
          :id="`retribution-${retributionId(row, column)}`"
          :name="column.field"
          v-model="row[column.field]"
          @input="$emit('editRetribution', retributionId(row, column), row[column.field])"
        />
      </template>
      <template #retribution="{ row, column }">
        <span>{{ row[column.field] ? formatPrice(row[column.field]) : "—" }}</span>
      </template>
    </Table>
    <Alert v-else>{{ $t("no_data") }}</Alert>
  </div>
</template>

<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { Alert, Table } from "@estaly/ui";
import FormField from "@/components/utils/FormField.vue";

export default {
  emits: ["editRetribution"],

  components: { Alert, Table, FormField },

  props: {
    store: {
      type: Object,
      required: true,
    },
    retributions: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      baseColumns: [
        { field: "category", label: "Formule", bold: true },
        { field: "priceBand", label: "Tranche" },
      ],
    };
  },

  computed: {
    columns() {
      let periods = [];
      const isMonthlyBilling = this.retributions.find((row) => row.monthlyBilling) !== undefined;
      periods = periods.concat(
        this.months.map((month) => {
          const years = month / 12;
          return {
            field: `${month}Commission`,
            label: `${years} ${this.$t("year", years)}`,
            headerGroup: "Grille de rémunération",
          };
        }),
      );
      if (isMonthlyBilling) {
        periods.push({
          field: "monthlyCommission",
          label: this.$t("termLength.monthly"),
          headerGroup: "Grille de rémunération",
        });
      }
      periods = periods.concat(
        this.months.map((month) => {
          const years = month / 12;
          return {
            field: `${month}Retribution`,
            label: `${years} ${this.$t("year", years)}`,
            slot: this.editable ? "editable" : "retribution",
            headerGroup: "Rétribution vendeur",
          };
        }),
      );
      if (isMonthlyBilling) {
        periods.push({
          field: "monthlyRetribution",
          label: this.$t("termLength.monthly"),
          slot: this.editable ? "editable" : "retribution",
          headerGroup: "Rétribution vendeur",
        });
      }
      return this.baseColumns.concat(periods);
    },

    rows() {
      return this.categories.map((category) => this.rowGroupedByPriceBand(category)).flat();
    },

    categories() {
      return [...new Set(this.retributions.map((row) => row.category))];
    },

    months() {
      const months = this.retributions.filter((row) => !row.monthlyBilling);
      return [...new Set(months.map((row) => row.termLength))].sort((a, b) => a - b);
    },
  },

  methods: {
    formatPrice,

    retributionId(row, column) {
      return row[`${column.field}Id`];
    },

    rowGroupedByPriceBand(category) {
      const data = this.retributions.filter((row) => row.category === category);
      const bands = [...new Set(data.map((row) => row.priceBandMin))];
      return [
        ...bands
          .map((band) => {
            const priceBandData = data.filter((row) => row.priceBandMin === band);
            const retribution = priceBandData[0];
            return {
              band,
              category,
              priceBand: `${formatPrice(retribution.priceBandMin)} - ${formatPrice(retribution.priceBandMax)}`,
              ...this.getMonthlyCommission(priceBandData),
              ...this.getYearlyCommissions(priceBandData),
              ...this.getMonthlyRetribution(priceBandData),
              ...this.getYearlyRetributions(priceBandData),
            };
          })
          .sort((a, b) => a.band - b.band),
      ];
    },

    getMonthlyCommission(data) {
      const monthlyData = data.find((row) => row.monthlyBilling);
      if (!monthlyData) return {};

      return { monthlyCommission: formatPrice(monthlyData.storeCommission) };
    },

    getYearlyCommissions(data) {
      const map = new Map(
        this.months.map((month) => {
          const yearlyData = data.find((row) => row.termLength === month);
          return [`${month}Commission`, formatPrice(yearlyData?.storeCommission)];
        }),
      );
      return Object.fromEntries(map.entries());
    },

    getMonthlyRetribution(data) {
      const monthlyData = data.find((row) => row.monthlyBilling);
      if (!monthlyData) return {};

      return { monthlyRetribution: monthlyData.netAmount, monthlyRetributionId: monthlyData.id };
    },

    getYearlyRetributions(data) {
      const columns = {};
      this.months.map((month) => {
        const yearlyData = data.find((row) => !row.monthlyBilling && row.termLength === month);
        if (!yearlyData) return;

        columns[`${month}Retribution`] = yearlyData.netAmount;
        columns[`${month}RetributionId`] = yearlyData.id;
      });

      return columns;
    },
  },

  watch: {
    retributions: {
      deep: true,
    },
  },
};
</script>
