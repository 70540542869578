<template>
  <div>
    <div>
      <p class="text-sm">
        Renseignez les coordonnées des utilisateurs qui seront invités à collaborer avec vous. Consultez le détail des
        rôles ici.
      </p>
      <FormField
        id="email"
        label="Adresse email vendeurs"
        labelClass="font-normal"
        name="email"
        placeholder="abc@store.com"
        required
        v-model="formData.email"
      />
      <p class="mt-2 text-xs text-gray-600">Renseignez l'adresse email à laquelle vos vendeurs ont accès.</p>
      <hr class="my-4" />
      <h3 class="font-bold pb-2">Coordonnées du manager du magasin</h3>
      <p class="text-xs text-gray-600">
        Le manager correspond au responsable du magasin en charge des opérations du quotidien. Ces informations sont
        cruciales pour le planning et le suivi des formations.
      </p>
      <Checkbox
        class="my-2"
        id="current_profile_is_referent"
        name="current_profile_is_referent"
        label="Cochez cette case uniquement si vous êtes le manager du magasin"
        labelClass="font-normal"
        v-model="formData.currentUserIsReferent"
      />
      <FormField
        id="referent_last_name"
        label="Nom du manager"
        labelClass="font-normal"
        name="referent_last_name"
        required
        v-model="formData.referent.lastName"
      />
      <FormField
        id="referent_first_name"
        label="Prénom du manager"
        labelClass="font-normal"
        name="referent_first_name"
        required
        v-model="formData.referent.firstName"
      />
      <FormField
        id="referent_email"
        label="Email du manager"
        labelClass="font-normal"
        name="referent_email"
        placeholder="Email du manager"
        required
        v-model="formData.referent.email"
      />
      <FormField
        id="referent_phone"
        label="Numéro de téléphone du manager"
        labelClass="font-normal"
        name="referent_phone"
        placeholder="06 12 34 56 78"
        required
        v-model="formData.referent.phone"
      />
      <Selector
        id="referent_role"
        label="Fonction du manager"
        labelClass="font-normal"
        name="referent_role"
        required
        v-model="formData.referentRole"
        :options="referentRoles"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading" />
      <GenericButton v-else @click="updateContactInformations">
        <span v-if="store[configStatus] == 'pending_validation'">Modifier</span>
        <span v-else>Valider</span>
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import Loading from "@/components/utils/Loading.vue";
import FormField from "@/components/utils/FormField.vue";
import Checkbox from "@/components/utils/Checkbox.vue";
import StoreService from "@/api/services/store";
import { mapActions, mapState } from "vuex";

export default {
  props: ["store", "configStatus"],

  components: {
    GenericButton,
    Selector,
    Checkbox,
    FormField,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      formData: {
        email: "",
        currentUserIsReferent: false,
        referent: {
          lastName: "",
          firstName: "",
          email: "",
          phone: "",
        },
        referentRole: "",
      },
      referentRoles: [],
    };
  },

  created() {
    this.setupFormData();
  },

  emits: ["configurationUpdated"],

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("auth", ["refreshUser"]),
    async setupFormData() {
      this.isLoading = true;
      const response = await StoreService.getStoreContactInformations(this.store.id);
      if (response?.success) {
        const {
          data: { store, referent_roles },
        } = response;
        this.formData.email = store.email;
        this.formData.currentUserIsReferent = store.current_profile_is_referent;
        this.formData.referent.lastName = store.referent.last_name;
        this.formData.referent.firstName = store.referent.first_name;
        this.formData.referent.email = store.referent.email;
        this.formData.referent.phone = store.referent.phone;
        this.formData.referentRole = store.referent_role;
        this.referentRoles = referent_roles;
      }
      this.isLoading = false;
    },
    async updateContactInformations() {
      const storeId = this.store.id;
      const { success } = await StoreService.updateStoreContactInformation(storeId, {
        store: {
          email: this.formData.email,
          current_profile_is_referent: this.formData.currentUserIsReferent,
          referent_role: this.formData.referentRole,
          referent: {
            last_name: this.formData.referent.lastName,
            first_name: this.formData.referent.firstName,
            email: this.formData.referent.email,
            phone: this.formData.referent.phone,
          },
        },
      });
      if (!success) return;

      if (this.formData.currentUserIsReferent) this.refreshUser(); // currentUser profile was updated, so refresh data
      this.$emit("configurationUpdated");
      this.$emit("stepValidated");
      this.notify({
        category: "simple",
        type: "success",
        title: "Informations mises à jour",
        text: "Les coordonées du magasin ont été mises à jour avec succès",
      });
    },
  },
  watch: {
    "formData.currentUserIsReferent"(newValue) {
      if (newValue === true) {
        this.formData.referent.email = this.currentUser.email;
        this.formData.referent.firstName = this.currentUser.firstName;
        this.formData.referent.lastName = this.currentUser.lastName;
      } else {
        this.formData.referent.email = "";
        this.formData.referent.firstName = "";
        this.formData.referent.lastName = "";
      }
    },
  },
};
</script>
