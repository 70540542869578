<template>
  <div>
    <div>
      <p class="text-sm">
        Indiquez si votre magasin dispose d'un atelier et est en mesure d'effectuer les réparations en cas de sinistre.
      </p>
    </div>
    <div>
      <Selector
        id="repair_workshop"
        label="Atelier de réparation"
        labelClass="font-normal"
        name="repair_workshop"
        required
        v-model="formData.repairWorkshop"
        :options="repairWorkshops"
      />
      <FormField
        v-if="repairWorkshopPresent"
        id="repair_workshop_email"
        label="Email de l’atelier de réparation"
        labelClass="font-normal"
        name="repair_workshop_email"
        required
        v-model="formData.repairWorkshopEmail"
      />
      <p v-if="repairWorkshopPresent" class="mt-2 text-xs text-gray-600">
        Adresse à laquelle l'atelier sera notifié des étapes à suivre à la suite d'un sinistre.
      </p>
    </div>
    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading" />
      <GenericButton v-else @click="updateAfterSalesService">
        <span v-if="store[configStatus] == 'pending_validation'">Modifier</span>
        <span v-else>Valider</span>
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import FormField from "@/components/utils/FormField.vue";
import StoreService from "@/api/services/store";
import { mapActions } from "vuex";

export default {
  props: ["store", "configStatus"],

  components: {
    GenericButton,
    Selector,
    FormField,
  },

  data() {
    return {
      isLoading: false,
      formData: {
        repairWorkshop: false,
        repairWorkshopEmail: "",
      },
      repairWorkshops: [
        { value: true, label: "Oui" },
        { value: false, label: "Non" },
      ],
    };
  },

  created() {
    this.setupFormData();
  },

  computed: {
    repairWorkshopPresent() {
      return this.formData.repairWorkshop === "true";
    },
  },
  emits: ["configurationUpdated"],
  methods: {
    ...mapActions("notifications", ["notify"]),
    async setupFormData() {
      this.isLoading = true;
      const response = await StoreService.getAfterSalesService(this.store.id);
      if (response?.success) {
        this.formData.repairWorkshop = response.data.repair_workshop;
        this.formData.repairWorkshopEmail = response.data.repair_workshop_email;
      }
      this.isLoading = false;
    },
    async updateAfterSalesService() {
      const storeId = this.store.id;
      const { success } = await StoreService.updateAfterSalesService(storeId, this.formData);
      if (!success) return;

      this.$emit("configurationUpdated");
      this.notify({
        category: "simple",
        type: "success",
        title: "Informations mises à jour",
        text: "Les informations ont été mises à jour avec succès",
      });
    },
  },
};
</script>
