<template>
  <div v-if="store">
    <div class="bg-white shadow sm:rounded-lg mt-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 px-4 py-5 sm:px-6">
        <div class="flex justify-center items-center">
          <img :src="store.logoUrl" :alt="store.salesChannel" class="max-w-full h-auto" />
        </div>
        <div>
          <h3 class="px-4 text-lg leading-6 font-medium text-gray-900 font-bold">
            {{ store.salesChannel }}
          </h3>
          <div>
            <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
              <div>
                <dt class="text-sm font-bold text-gray-500">{{ $t("Id") }}</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <FieldSpan :style-to-add="'bg-purple-600 text-white'" :value="store.id" />
                </dd>
              </div>
              <div>
                <dt class="text-sm font-bold text-gray-500">
                  {{ $t("Organization name") }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ store.organizationName }}
                </dd>
              </div>
              <div>
                <dt class="text-sm font-bold text-gray-500">
                  {{ $t("Source") }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ $t(store.source) }}
                </dd>
              </div>
              <div>
                <dt class="text-sm font-bold text-gray-500">
                  {{ $t("Public subscription link") }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900 flex items-center">
                  <a
                    :href="store.publicSubscriptionLink"
                    target="_blank"
                    class="text-primary-dark underline mr-2 break-words w-full"
                  >
                    {{ store.publicSubscriptionLink }}
                  </a>
                  <ClipboardIcon
                    class="cursor-pointer w-8 h-8 text-primary-dark"
                    @click="copyToClipboard(store.publicSubscriptionLink)"
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <!-- Performance Monitoring Section (only visible if source is offline) -->
    <div v-if="isOfflineStore" class="bg-white shadow sm:rounded-lg mt-6">
      <h3 class="px-4 py-4 text-lg leading-6 font-medium text-gray-900 font-bold">
        {{ $t("Performance monitoring") }}
      </h3>
      <div class="px-4 py-5 grid grid-cols-3 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Monthly sales target") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.monthlySalesTarget }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Sales field manager name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.salesFieldManagerFullName }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Sales field manager email") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.salesFieldManagerEmail }}
          </dd>
        </div>
      </div>
    </div>

    <div class="bg-white shadow sm:rounded-lg mt-6">
      <div class="flex justify-between">
        <h3 class="px-4 py-4 text-lg leading-6 font-medium text-gray-900 font-bold">
          {{ $t("Invoicing") }}
        </h3>
        <PencilIcon
          v-if="isStoreInvoiceLevel"
          class="mx-4 my-4 cursor-pointer w-6 h-6 text-primary-dark"
          @click="openBillingModal"
        />
      </div>
      <div class="px-4 py-5 grid grid-cols-3 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Billing entity") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.billingEntity }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Billing address") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.billingAddress }}
          </dd>
        </div>
        <div v-if="isStoreInvoiceLevel">
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Iban") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.iban || "_" }}
          </dd>
        </div>
        <div v-if="store.billingInvoiceMonthsDelay">
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Billing delay") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">{{ store.billingInvoiceMonthsDelay }} {{ $t("months") }}</dd>
        </div>
        <div v-if="store.advanceMonthsCommission">
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Advance commission") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ $t("Yes") }}
          </dd>
        </div>
      </div>
    </div>

    <!-- Discount Section (only visible if source is offline) -->
    <div v-if="isOfflineStore && store.discount" class="bg-white shadow sm:rounded-lg mt-6">
      <h3 class="px-4 py-4 text-lg leading-6 font-medium text-gray-900 font-bold">
        {{ $t("Discount") }}
      </h3>
      <div class="px-4 py-5 grid grid-cols-1 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Special offer for your customers") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ store.discount }}
          </dd>
        </div>
      </div>
    </div>

    <!-- Offers Section -->
    <div class="shadow sm:rounded-lg mt-6">
      <OffersList
        :matchedContracts="store.matchedContracts"
        :storeId="store.id"
        :isOfflineStore="isOfflineStore"
        @reloadStore="$emit('reloadStore')"
      />
    </div>

    <!-- Billing Modal -->
    <BillingModal
      :openModal="isBillingModalOpen"
      :iban="store.iban"
      @closeBillingModal="closeBillingModal"
      @updateIban="updateIban"
    />
  </div>
  <div v-else>
    <p>{{ $t("Loading...") }}</p>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import OffersList from "@/views/admin/store/details/active/OffersList.vue";
import BillingModal from "@/views/admin/store/details/active/BillingModal.vue";
import StoreService from "@/api/services/store";
import { ClipboardIcon, PencilIcon } from "@heroicons/vue/solid";
import { mapActions } from "vuex";

export default {
  components: {
    FieldSpan,
    OffersList,
    BillingModal,
    ClipboardIcon,
    PencilIcon,
  },
  props: ["store"],
  computed: {
    isOfflineStore() {
      return this.store.source === "Offline";
    },
    isStoreInvoiceLevel() {
      return this.store.invoiceLevel === "store";
    },
  },
  data() {
    return {
      isBillingModalOpen: false,
    };
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
    openBillingModal() {
      this.isBillingModalOpen = true;
    },
    closeBillingModal() {
      this.isBillingModalOpen = false;
    },
    async updateIban(iban) {
      try {
        const data = {
          iban: iban,
        };
        await StoreService.updateStore(this.store.id, data);
        await this.notify({
          category: "simple",
          type: "success",
          title: "L'Iban a bien été mis à jour",
        });
        this.$emit("reloadStore");
        this.closeBillingModal();
      } catch (error) {
        const errorMessage = error.response.data.error || error.message;
        await this.notify({
          category: "simple",
          type: "error",
          text: errorMessage,
        });
      }
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.store.publicSubscriptionLink)
        .then(() => {
          this.notify({
            category: "simple",
            type: "success",
            title: "Link copied to clipboard!",
          });
        })
        .catch((error) => {
          console.error("Error when copying to clipboard : ", error);
          this.notify({
            category: "simple",
            type: "error",
          });
        });
    },
  },
};
</script>
