<template>
  <div>
    <ProductListMenu
      v-bind:selected-products="selectedProducts"
      v-bind:current-page="currentPage"
      @upload="this.uploadProductsToPlatform"
      @remove="this.removeProductsFromPlatform"
      @updateKeywordSearch="this.updateKeywordSearch"
      @updateFilterSearch="this.updateFilterSearch"
    />
    <ProductSelectionInformation
      v-bind:selected-products="this.selectedProducts"
      v-bind:products="this.allProducts"
      @unselectAll="this.unselectAllProducts"
    />
    <ProductListContent
      v-bind:products="this.allProducts"
      v-bind:selected-products="this.selectedProducts"
      v-bind:all-products-selected="this.allProductsSelected"
      @selectProducts="this.handleProductSelection"
      @unselectProducts="this.handleProductUnselection"
    />
    <SimplePagination v-bind:currentPage="currentPage" v-on:changePage="handleChangePage" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StoreService from "@/api/services/store";
import ProductListContent from "@/views/store/products/list/content/ProductListContent.vue";
import ProductListMenu from "@/views/store/products/list/menu/ProductListMenu.vue";
import ProductSelectionInformation from "@/views/store/products/list/selection/ProductSelectionInformation.vue";
import SimplePagination from "@/components/utils/SimplePagination.vue";

export default {
  components: {
    ProductListContent,
    SimplePagination,
    ProductListMenu,
    ProductSelectionInformation,
  },
  data: () => {
    return {
      currentPage: 1,
      selectedProducts: [],
      allProductsSelected: false,
    };
  },
  watch: {
    currentPage(newPage) {
      this.retrieveProductsIfUserIsSignedIn(newPage);
    },
    selectedStoreId(newSelectedStoreId) {
      if (newSelectedStoreId !== 0) {
        this.retrieveProductsIfUserIsSignedIn(this.currentPage);
      }
    },
  },

  beforeMount() {
    this.retrieveProductsIfUserIsSignedIn(this.currentPage);
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("store", [
      "allProducts",
      "isLoading",
      "totalNumberOfProducts",
      "totalNumberOfProductsApproved",
      "approvedProductsIds",
      "selectedStoreId",
    ]),
    storeId() {
      return this.$store.state.store.selectedStoreId;
    },
  },
  methods: {
    ...mapActions("store", ["getProducts", "updateFilter", "updateSearch"]),
    ...mapState("store", ["allProducts"]),
    ...mapActions("notifications", ["notify"]),
    retrieveProductsIfUserIsSignedIn(page) {
      if (this.signedIn) {
        this.getProducts({
          storeId: this.$store.state.store.selectedStoreId,
          page: page,
          online: "true",
          parents: true,
        });
      } else {
        this.$router.push("/signin");
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
    },
    handleProductSelection(products) {
      const productsToAdd = products
        .filter((product) => !this.selectedProducts.includes(product.id))
        .map((product) => product.id);
      this.selectedProducts = this.selectedProducts.concat(productsToAdd);
    },
    handleProductUnselection(products) {
      const productsRefIds = products.map((product) => product.id);
      this.selectedProducts = this.selectedProducts.filter((refId) => !productsRefIds.includes(refId));
      this.allProductsSelected = false;
    },
    selectAllProducts() {
      this.allProductsSelected = true;
      this.selectedProducts = this.allProducts.map((p) => p.id);
    },
    unselectAllProducts() {
      this.selectedProducts = [];
      this.allProductsSelected = false;
    },
    updateKeywordSearch(keywords) {
      const newPage = 1;
      this.currentPage = newPage;
      this.unselectAllProducts();
      this.updateSearch({
        searchString: keywords,
        storeId: this.$store.state.store.selectedStoreId,
        page: newPage,
        online: "true",
        parents: true,
      });
    },
    updateFilterSearch(filterName, filterOptions) {
      const newPage = 1;
      this.currentPage = newPage;
      this.unselectAllProducts();
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        storeId: this.storeId,
        page: newPage,
        online: "true",
        parents: true,
      });
    },
    uploadProductsToPlatform() {
      this.updateMerchantProducts(true);
    },
    removeProductsFromPlatform() {
      this.updateMerchantProducts(false);
    },
    async updateMerchantProducts(enableProducts) {
      const response = await StoreService.productPlatformUpdate(
        enableProducts,
        this.selectedProducts,
        this.allProductsSelected,
        this.storeId,
        this.approvedProductsIds,
      );
      if (response?.success) {
        this.notify({
          category: "simple",
          type: "success",
          title: "Plans were successfully updated on your store.",
          text: "They will be available for sale on your store very shortly.",
        });
      }
      this.unselectAllProducts();
      this.retrieveProductsIfUserIsSignedIn(this.currentPage);
    },
  },
};
</script>
