<template>
  <div v-if="invoice">
    <div class="flex justify-between items-center my-4">
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900">
        {{ invoiceTitle }}
      </h1>
      <p>{{ invoice.entityName }}</p>
    </div>
    <div class="bg-white rounded-xl py-6 pl-4 mt-4 mb-8">
      <h3>{{ invoiceAmountSummary }}</h3>
    </div>
    <div>
      <div class="mb-4">
        <div v-if="tabs.length > 1">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.id"
                :href="generateTabLink(tab.id)"
                @click.prevent="selectTab(tab.id)"
                :class="[
                  tab.active
                    ? 'border-primary text-primary-dark'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
                ]"
              >
                {{ tab.name }}
              </a>
            </nav>
          </div>
        </div>
      </div>

      <component :is="currentTab.component" :invoice="invoice" />
    </div>
  </div>
</template>

<script>
import AdminService from "@/api/services/admin";
import { mapActions } from "vuex";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { isPresent } from "@/utils/validation";

import InvoiceBreakdown from "@/components/invoice/InvoiceBreakdown.vue";
import InvoiceItems from "@/components/invoice/InvoiceItems.vue";

export default {
  components: {
    InvoiceBreakdown,
    InvoiceItems,
  },
  data() {
    return {
      invoice: null,
      tabs: [],
    };
  },

  computed: {
    currentTab() {
      return this.tabs.find((tab) => tab.active);
    },

    invoiceTitle() {
      if (!this.invoice) return "";
      if (this.invoice.transactionType === "statement") {
        return this.$t("invoice.statementTitle", { period: this.invoice.period });
      } else if (this.invoice.transactionType === "bill") {
        return this.$t("invoice.billTitle", { period: this.invoice.period });
      }
      return "";
    },

    invoiceAmountSummary() {
      if (!this.invoice) return "";
      if (this.invoice.transactionType === "statement") {
        return this.$t("invoice.statementAmount", { amount: formatPrice(this.invoice.amount) });
      } else if (this.invoice.transactionType === "bill") {
        return this.$t("invoice.billAmount", { amount: formatPrice(this.invoice.amount) });
      }
      return "";
    },
  },

  beforeMount() {
    this.initializeTabs();
    this.syncTabWithUrl();
    this.getInvoice();
  },

  watch: {
    $route: "syncTabWithUrl",
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,
    isPresent,

    initializeTabs() {
      this.tabs = [
        {
          id: "invoice-breakdown",
          name: "Répartition des ventes",
          active: true,
          component: InvoiceBreakdown,
        },
        {
          id: "invoice-items",
          name: "Détails des encaissements",
          active: false,
          component: InvoiceItems,
        },
      ];
    },

    selectTab(selectedTabId, replaceUrl = true) {
      this.tabs.forEach((tab) => {
        tab.active = tab.id === selectedTabId;
      });

      if (replaceUrl) {
        this.$router.push({ query: { tab: selectedTabId } });
      } else {
        this.$router.replace({ query: { tab: selectedTabId } });
      }
    },

    syncTabWithUrl() {
      const tabId = this.$route.query.tab || "invoice-breakdown";
      if (tabId !== this.currentTab.id) {
        this.selectTab(tabId, false);
      }
    },

    generateTabLink(tabId) {
      return this.$router.resolve({ query: { tab: tabId } }).href;
    },

    async getInvoice() {
      const response = await AdminService.getInvoiceByCustomId(this.$route.params.id);
      if (response?.success) {
        this.invoice = response.data.invoice;
      }
    },
  },
};
</script>
