<template>
  <div class="flex flex-col gap-y-4">
    <Breakdown :invoice="invoice" :recurringBreakdown="false" />
    <Breakdown :invoice="invoice" :recurringBreakdown="true" />
  </div>
</template>

<script>
import Breakdown from "@/components/invoice/breakdown/Breakdown.vue";

export default {
  components: { Breakdown },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
};
</script>
