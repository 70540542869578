<template>
  <ProductSaleForm
    v-bind:marketplace="this.marketplace"
    v-bind:categories="this.categories"
    v-bind:offeredMonths="this.offeredMonths"
  />
</template>

<script>
import StoreService from "@/api/services/store";
import { mapState } from "vuex";
import ProductSaleForm from "@/views/pos/home/form/ProductSaleForm.vue";

export default {
  components: {
    ProductSaleForm,
  },

  data: () => {
    return {
      marketplace: false,
      categories: [],
      offeredMonths: null,
    };
  },

  watch: {
    selectedStoreId(newValue) {
      if (newValue !== 0) {
        this.getCategories();
      }
    },
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
  },

  beforeMount() {
    this.getCategories();
    this.getDiscounts();
  },

  methods: {
    async getCategories() {
      const response = await StoreService.getCategories(this.$store.state.store.selectedStoreId);
      if (response?.success) {
        this.marketplace = response.data.marketplace;
        this.categories = response.data.categories;
      }
    },

    async getDiscounts() {
      const response = await StoreService.getDiscount(this.$store.state.store.selectedStoreId);
      if (!response?.success) return;

      this.offeredMonths = response.data.discount.offeredMonths;
    },
  },
};
</script>
