import { securedAxiosInstance, plainAxiosInstance } from "../axios";

class AnalyticsService {
  getRetailStoresData(startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/retail/stores", {
      params: {
        start: startDate,
        end: endDate,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
      },
    });
  }

  getRetailSellersData(storeId, startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/retail/sellers", {
      params: {
        start: startDate,
        end: endDate,
        store_id: storeId,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
      },
    });
  }

  getGeneralAnalytics({ startDate, endDate, stores }) {
    return securedAxiosInstance.get("/admin/analytics", {
      params: {
        start_date: startDate,
        end_date: endDate,
        stores,
      },
    });
  }

  computeOrganizationAnalytics(params) {
    return plainAxiosInstance.get("/analytics", { params });
  }
}

export default new AnalyticsService();
