<template>
  <div>
    <div>
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr class="hidden md:table-row">
                    <th
                      v-if="isAdminPage"
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Magasin
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Plan") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Product") }}
                    </th>
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    >
                      Client
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Charged") }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="(planSale, id) in this.planSales"
                    v-bind:key="id"
                    :class="{
                      'bg-red-100': is_cancelled(planSale),
                      'bg-gray-200': is_expired(planSale),
                      'cursor-pointer hover:bg-gray-100': true,
                    }"
                    @click="redirectToDetails(planSale.id)"
                  >
                    <td
                      v-if="isAdminPage"
                      class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ planSale.salesChannel }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      <div class="flex flex-col">
                        <span>
                          <span class="font-medium">{{ planSale.title }}</span>
                          à
                          <span class="font-medium">{{ formatPrice(planSale.price) }}{{ by_month(planSale) }}</span>
                        </span>
                        <span class="font-light italic">{{ planSale.id }} - {{ planSale.purchaseDate }}</span>
                        <span v-if="planSale.orderReferenceId" class="font-light italic">
                          Numéro de commande : {{ planSale.orderReferenceId }}
                        </span>
                        <span class="font-light italic" v-if="is_cancelled(planSale)">
                          Résilié le {{ planSale.cancellationDate }}
                        </span>
                      </div>
                      <div class="flex flex-col md:hidden">
                        <span class="font-light italic">
                          {{ planSale.product.title }} ({{ planSale.product.category }}) à
                          {{ formatPrice(planSale.product.price) }}
                        </span>
                        <span class="font-light italic">{{ planSale.customer.email }}</span>
                      </div>
                    </td>
                    <td class="hidden md:table-cell py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <span>{{ planSale.product.title }}</span>
                      <div class="flex">
                        <span class="font-light italic mr-2">{{ formatPrice(planSale.product.price) }}</span>
                        -
                        <span class="ml-2 font-light italic">{{ planSale.product.category }}</span>
                      </div>
                    </td>
                    <td
                      class="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      <div class="flex flex-col">
                        <span>{{ planSale.customer.email }}</span>
                        <span class="font-light italic">
                          {{ planSale.customer.firstName }} {{ planSale.customer.lastName }}
                        </span>
                      </div>
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        v-bind:class="[
                          planSale.chargedBy === 'Merchant'
                            ? 'bg-green-100 text-green-800'
                            : planSale.chargedBy === 'Estaly'
                              ? 'bg-blue-100 text-blue-800'
                              : 'bg-blue-100 text-blue-800',
                          '',
                          'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ',
                        ]"
                      >
                        {{ planSale.chargedBy }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Pagination
    v-bind:currentPage="this.currentPage"
    v-bind:pages="pages"
    v-bind:startIdx="startIdx"
    v-bind:endIdx="endIdx"
    v-bind:total="this.totalNumberOfPlanSales"
    v-on:changePage="this.handleChangePage"
    v-if="pages.length > 1"
  />
</template>

<script>
import Pagination from "@/components/utils/Pagination.vue";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { mapGetters } from "vuex";

const PAGE_SIZE = 25;

export default {
  components: { Pagination },
  props: ["planSales", "currentPage", "totalNumberOfPlanSales"],

  computed: {
    ...mapGetters("navigation", ["isAdminPage"]),

    startIdx() {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx() {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfPlanSales);
    },

    pages() {
      if (this.isLoading || this.totalNumberOfPlanSales < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfPlanSales / PAGE_SIZE)).keys()].map((e) => e + 1);
    },
  },

  methods: {
    formatPrice,
    by_month(planSale) {
      return planSale.monthlyBilling ? "/mois" : "";
    },
    is_cancelled(planSale) {
      return planSale.status === "cancelled";
    },

    is_expired(planSale) {
      return planSale.status === "expired";
    },

    handleChangePage(newPage) {
      this.$emit("updatePage", newPage);
    },

    redirectToDetails(planSaleId) {
      const currentRoute = this.$route.path;
      this.$router.push(`${currentRoute}/${planSaleId}`);
    },
  },
};
</script>
