<template>
  <div>
    <div class="flex items-start my-6">
      <div class="flex flex-col w-full">
        <div class="sm:w-1/3 flex justify-between">
          <p class="font-body-bold text-lg">Sélectionner une période</p>
          <button @click="resetToBeginning" class="text-primary-dark underline text-xs">Depuis le début</button>
        </div>
        <div class="flex mb-2 w-full sm:w-1/3 flex-col">
          <Datepicker
            class="text-sm rounded-md"
            v-model="date"
            range
            multiCalendars
            v-bind:presetRanges="presetRanges"
            autoApply
            v-bind:enableTimePicker="false"
            v-bind:format="datePickerFormat"
            locale="fr"
            :max-date="new Date()"
            :esc-close="false"
          />
        </div>
      </div>
    </div>
    <SellerAnalytics
      v-if="sellerViewEnabled && stats"
      :currentMonth="currentMonth"
      :stats="stats"
      :total="total"
      @storeIdSelected="storeIdSelected"
      @sortChanged="onSellersSortChange"
    />
    <StoreAnalytics
      v-else-if="stats"
      :currentMonth="currentMonth"
      :stats="stats"
      :total="total"
      @storeIdSelected="storeIdSelected"
      @sortChanged="onStoresSortChange"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";

import StoreAnalytics from "@/components/analytics/retail/StoreAnalytics.vue";
import SellerAnalytics from "@/components/analytics/retail/SellerAnalytics.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { endOfMonth, startOfMonth, subMonths, startOfWeek, subWeeks, endOfWeek, format } from "date-fns";
import { mapActions, mapGetters, mapState } from "vuex";
import StoreService from "@/api/services/store";
import { currentMonth, previousMonth, currentWeek, previousWeek } from "@/utils/date";

export default {
  components: {
    Datepicker,
    StoreAnalytics,
    SellerAnalytics,
  },

  data: () => {
    return {
      sellerViewEnabled: false,
      stats: null,
      total: null,
      storesSortParams: {
        column: null,
        order: null,
      },
      sellersSortParams: {
        column: null,
        order: null,
      },
      date: [],
      currentMonth: "",
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [startOfWeek(new Date(), { weekStartsOn: 1 }), endOfWeek(new Date(), { weekStartsOn: 1 })],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
      ],
      comparedTo: {},
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapGetters("store", ["selectedStoreId"]),
  },

  created() {
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["withLoader"]),

    datePickerFormat(date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (startDate instanceof Date && endDate instanceof Date) {
          if (currentMonth(startDate, endDate)) {
            return "Mois en cours";
          }
          if (previousMonth(startDate, endDate)) {
            return "Mois précédent";
          }
          if (currentWeek(startDate, endDate)) {
            return "Semaine en cours";
          }
          if (previousWeek(startDate, endDate)) {
            return "Semaine précédente";
          }
        }
      }

      return `From ${startDay}/${startMonth}/${startYear} to ${endDay}/${endMonth}/${endYear}`;
    },

    storeIdSelected() {
      this.sellerViewEnabled = !this.sellerViewEnabled;
    },

    async getAnalytics() {
      const start = Array.isArray(this.date) && this.date[0] ? format(this.date[0], "yyyy-MM-dd") : null;
      const end = Array.isArray(this.date) && this.date[1] ? format(this.date[1], "yyyy-MM-dd") : null;
      const sortParams = this.retrieveSortParams();
      const storeId = this.selectedStoreId;

      let response;
      if (this.sellerViewEnabled) {
        response = await StoreService.getRetailSellersData(storeId, start, end, sortParams);
      } else {
        response = await StoreService.getRetailSalesData(storeId, start, end, sortParams);
      }
      if (response?.success) {
        const data = response.data;
        this.stats = data.stats;
        this.total = data.total;
      }
    },

    updateCurrentMonth(endDate) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      const month = monthNames[endDate.getMonth()];
      const year = endDate.getFullYear();
      this.currentMonth = `${month} ${year}`;
    },

    onStoresSortChange(sortParams) {
      this.storesSortParams = sortParams;
      this.withLoader(this.getAnalytics);
    },

    onSellersSortChange(sortParams) {
      this.sellersSortParams = sortParams;
      this.withLoader(this.getAnalytics);
    },

    resetToBeginning() {
      this.date = null;
      this.withLoader(this.getAnalytics);
    },

    retrieveSortParams() {
      if (this.sellerViewEnabled) {
        return this.sellersSortParams;
      } else {
        return this.storesSortParams;
      }
    },
  },
  watch: {
    async date(newDate) {
      if (Array.isArray(newDate) && newDate.length > 0 && newDate[0] && newDate[1]) {
        this.updateCurrentMonth(newDate[1]);
      }
      await this.withLoader(this.getAnalytics);
    },

    sellerViewEnabled() {
      this.withLoader(this.getAnalytics);
    },
  },
};
</script>
