<template>
  <div>
    <ClaimListTitle />
    <SearchBar
      :title="$t('Claim filters')"
      :placeholder="$t('SearchClaims')"
      :current-page="currentPage"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      :initial-keywords="keywords"
      @updateFilterSearch="updateFilterSearch"
      @updateKeywordSearch="updateKeywordSearch"
      class="my-4"
    />
    <PaginatedClaimList
      :total-number-of-claims="totalNumberOfClaims"
      :current-page="currentPage"
      :claims="claims"
      @changePage="handleChangePage"
      @redirectToClaimDetailsPage="redirectToClaimDetailsPage"
    />
  </div>
</template>

<script>
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import SearchBar from "@/components/menu/SearchBar.vue";
import ClaimListTitle from "@/components/claim/list/ClaimListTitle.vue";
import { getQueryParams, setQueryParams } from "@/utils/query_params";

export default {
  components: {
    ClaimListTitle,
    SearchBar,
    PaginatedClaimList,
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("claim_list", ["getClaims"]),
    ...mapState("claim_list", ["claims", "totalNumberOfClaims", "availableFilters", "selectedFilters", "keywords"]),
  },

  methods: {
    ...mapActions("claim_list", ["fetchClaims", "updateFilter", "updateSearch", "setFilters"]),

    handleChangePage(page) {
      this.updatePage(page);
    },

    redirectToClaimDetailsPage(claimId) {
      this.$router.push(`/admin/claim/${claimId}`);
    },

    updateFilterSearch(filterName, filterOptions) {
      this.updatePage(1, false);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: this.currentPage,
      });
    },

    updateKeywordSearch(keyword) {
      this.updatePage(1, false);
      this.updateSearch({
        searchString: keyword,
        page: this.currentPage,
      });
    },

    updatePage(newPage, refreshClaims = true) {
      this.currentPage = newPage;
      if (refreshClaims) {
        setQueryParams({ page: newPage });
        this.fetchClaims({ page: this.currentPage });
      }
    },
  },

  mounted() {
    const queryParams = getQueryParams();

    this.setFilters({ filters: queryParams });
    this.currentPage = parseInt(queryParams.page) || 1;
    this.fetchClaims({ page: this.currentPage });
  },
};
</script>
