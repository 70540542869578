import _ from "lodash";

function capitalizeFirstLetter(string) {
  return string !== null ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

function camelCaseToWords(string) {
  return string
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, "$1")
    .toLowerCase();
}

function toSnakeCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((result, key) => {
      const snakeCaseKey = _.snakeCase(key);
      result[snakeCaseKey] = toSnakeCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
}

function toCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => toCamelCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((result, key) => {
      const newKey = _.camelCase(key);
      result[newKey] = toCamelCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
}


export { capitalizeFirstLetter, camelCaseToWords, toSnakeCase, toCamelCase };
