const NEW_PASSWORD_URL = "/user/passwords";

const PRODUCTS_URL = "/store/products";
const STORES_URL = "/stores";
const ANALYTICS_URL = "/store/analytics";

const STORE_OFFLINE_SALE_URL = "/store/offline_sale";
const STORE_PLAN_SALES_URL = "/store/plan_sales";
const ADMIN_PLAN_SALES_URL = "/admin/plan_sales";

export {
  PRODUCTS_URL,
  NEW_PASSWORD_URL,
  STORE_PLAN_SALES_URL,
  STORES_URL,
  ANALYTICS_URL,
  STORE_OFFLINE_SALE_URL,
  ADMIN_PLAN_SALES_URL,
};
